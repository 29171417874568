$(function() {



    $(window).scroll(function() {
        if ( this.pageYOffset > 0 ) {
            $('.header').addClass('fixed')

        } else {
            $('.header').removeClass('fixed')

        }
    }).trigger('scroll')


    if ($(window).width() > 760) {
        $(".js-sticky").stick_in_parent({
            'offset_top': 100
        });
    }


    $('.js-toggler').on('click', function() {
        $('.header__hld').addClass('open')
        $('html').css({ overflowY: 'hidden' })
        return false
    })

    $('.header__hld-close').on('click', function() {
        $('.header__hld').removeClass('open')
        $('html').css({ overflowY: 'auto' })
        return false
    })




    $('.js-reviews-slider').slick({
        dots: false,
        infinite: false,
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    dots: true
                }
            }
        ]
    })

    $('.reviews__text').each(function() {
        if($(this).closest('.reviews__body').find('.reviews__text').length > 160) {
            if ($(this).height() > 46) {
                $(this).height('100%').addClass('active')
            }
        } else {
            if ($(this).height() > 160) {
                $(this).height('160').addClass('active')
            }
        }
    })

    $('body').on('click', '.reviews__more', function() {
        $(this).parent('.reviews__text').addClass('active')
        return false
    })




    $('.js-operation-slider').slick({
        dots: false,
        infinite: false,
        slidesToShow: 3,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    dots: true
                }
            }
        ]
    })




})
